import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Loadable from 'react-loadable';
import { ClipLoader } from 'react-spinners';

const Main = Loadable({
  loader: () => import('./Main.jsx'),
  loading() {
    return (
      <div style={{ textAlign: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#53517a', display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img alt="loading_image" src="/icons/logo.png" style={{ height: 150 }}/>
          </div><br/>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <ClipLoader
              color={'#fa9e2e'} 
              loading={true} 
              size={40}
            />
          </div>
        </div>
      </div>
    )
  }
});

ReactDOM.render(<Main />, document.getElementById('root'));
serviceWorker.unregister();
